export const adminAbility = [
    {
        action: 'manage',
        subject: 'Dashboard'
    },
    {
        action: 'manage',
        subject: 'Profile'
    },
    {
        action: 'manage',
        subject: 'Customers'
    },
    {
        action: 'manage',
        subject: 'Subscriptions'
    },
    {
        action: 'manage',
        subject: 'Companies'
    },
    {
        action: "manage",
        subject: 'Statistics Admin'
    },
    {
        action: 'manage',
        subject: 'Insurance review'
    }
]

export const accountHolderAbility = [
    {
        action: 'read',
        subject: 'Dashboard'
    },
    {
        action: 'manage',
        subject: 'Profile'
    },
    {
        action: 'manage',
        subject: 'Company Profile'
    },
    {
        action: 'manage',
        subject: 'Account holder subscriptions'
    },
    {
        action: 'manage',
        subject: 'JobSites'
    },
    {
        action: 'manage',
        subject: 'Employees'
    },
    {
        action: 'manage',
        subject: 'Tickets'
    },
    {
        action: 'manage',
        subject: 'Contact Requests'
    },
    {
        action: 'manage',
        subject: 'Scheduler'
    },
    {
        action: 'manage',
        subject: 'Chats'
    },
    {
        action: 'manage',
        subject: 'Time off requests'
    },
    {
        action: "manage",
        subject: 'Statistics'
    }
]
